<script setup>
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import Swal from "sweetalert2";
import moment from "moment";

const items = [
    {
        text: "Home",
        href: "/",
    },
    {
        text: "General",
    },
    {
        text: "Sales Plans` Targets",
        active: true,
    }
]

let years = ref([2025, 2024, 2023])
const positions = ref(['block_train', 'multi_modal', 'rail_forwarder',])
let targets = ref([])
let is_loading_targets = ref(false)

const getTargets = async () => {
    try {
        is_loading_targets.value = true
        let response = await axios.get('/core/sales_plan/list/')
        targets.value = response.data.results || []
        is_loading_targets.value = false
    } catch {
        targets.value = []
        is_loading_targets.value = false
    }
}

const getYearMonths = (year) => {
    const monthLabels = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const months = Array.from({ length: 12 }, (_, i) => ({
        year,
        month: { month_index: i + 1, month_label: monthLabels[i] },
        positions: {
            multi_modal: { id: null, value: "0.00" },
            rail_forwarder: { id: null, value: "0.00" },
            block_train: { id: null, value: "0.00" }
        }
    }));

    targets.value.forEach(target => {
        if (target.year === year) {
            const index = target.month - 1;
            months[index].positions[target.position] = {
                id: target.id || null,
                value: target.value
            };
        }
    });

    return months;
};

const getYearTotalTargetValue = (year, position) => {
    let total = 0;
    getYearMonths(year).forEach(month => {
        if (position) {
            total += parseFloat(month.positions[position].value || 0);
        } else {
            Object.values(month.positions).forEach(position => {
                total += parseFloat(position.value || 0);
            });
        }
    });
    return total.toFixed(2);
};

const getMonthTotalTargetValue = (positions) => {
    return Object.values(positions).map(i => parseFloat(i.value || '0.00')).reduce((sum, item) => sum + item, 0)
}


const saveItem = async (data) => {

    let Toast = Swal.mixin({
        toast: true,
        position: "top",
        showConfirmButton: false,
        timer: 3000,
    })

    try {
        const { id, year, month, position, value } = data
        if (id) {
            await axios.put(`/core/sales_plan/${id}/update/`, {
                year: year,
                month: month,
                value: parseFloat(value || 0).toFixed(2),
                position: position,
            })
            await Toast.fire({
                icon: "success",
                title: `Target updated for - ${moment(`${year}-${month}-01`).format('MMMM YYYY')}`,
            })
        } else {
            await axios.post(`/core/sales_plan/create/`, {
                year: year,
                month: month,
                value: parseFloat(value || 0).toFixed(2),
                position: position,
            })
            await Toast.fire({
                icon: "success",
                title: `Target created for - ${moment(`${year}-${month}-01`).format('MMMM YYYY')}`,
            })
        }
        await getTargets()
    } catch {
        alert("Error")
    }
}

const formatPrice = (price) => {
    return parseFloat(price || '0').toLocaleString(undefined, {
        minimumFractionDigits: 2, maximumFractionDigits: 2
    })
}

onMounted(async () => {
    await getTargets()
})
</script>

<template>
    <PageHeader :title="'Sales Plans` Targets'" :items="items" />

    <b-card no-body>

        <b-card-header>
            <ul class="nav nav-pills nav-success nav-justified card-footer-tabs fs-17" role="tablist">
                <li v-for="(year, index) in years" :key="`year_${year}`" class="nav-item" v-b-tooltip.hover
                    :title="year">
                    <b-link class="nav-link" :class="{
                        'active': index === 0
                    }" data-bs-toggle="tab" :href="`#year-tab-${year}`" role="tab">
                        {{ year }}
                    </b-link>
                </li>
            </ul>
        </b-card-header>

        <b-card-body>

            <div class="tab-content text-muted">
                <div v-for="(year, index) in years" :key="`year_content_${year}`" class="tab-pane" :class="{
                    'active': index === 0
                }" :id="`year-tab-${year}`" role="tabpanel">

                    <p class="fs-17">
                        Year <code class="fs-18 ms-1"> {{ year }}</code>

                    </p>

                    <div class="table-responsive">
                        <table class="table table-bordered table-nowrap">
                            <thead>
                                <tr class="align-middle">
                                    <th style="max-width: 100px;" scope="col">Month</th>
                                    <th scope="col">Block Train</th>
                                    <th scope="col">Multi Modal</th>
                                    <th scope="col">Rail Freight</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in getYearMonths(year)" :key="`month_${item.month.month_index}`"
                                    class="align-middle">
                                    <th style="max-width: 55px;" scope="row">
                                        <div class="d-flex justify-content-between">
                                            <span>{{ item.month.month_label }}</span>
                                            <div class="text-end">
                                                <small class="d-block fw-medium">
                                                    $ {{ formatPrice(getMonthTotalTargetValue(item.positions)) }}
                                                </small>
                                                <small class="fw-normal text-muted">
                                                    Month total
                                                </small>
                                            </div>
                                        </div>
                                    </th>
                                    <td v-for="position in positions" :key="`position_${position}`"
                                        style="max-width: 100px;">
                                        <form @submit.prevent="saveItem({
                                            id: item.positions[position].id,
                                            year: year,
                                            month: item.month.month_index,
                                            position: position,
                                            value: item.positions[position].value
                                        })">
                                            <div class="input-group">
                                                <input v-model="item.positions[position].value" type="text"
                                                    class="form-control"
                                                    :aria-describedby="`year-${year}-${item.month.month_index}`">
                                                <button class="btn" :class="{
                                                    'btn-success': item.positions[position].id,
                                                    'btn-outline-success': !item.positions[position].id,
                                                }" type="submit"
                                                    :id="`year-${year}-${item.month.month_index}`">Save</button>
                                            </div>
                                        </form>
                                    </td>
                                    <!-- <td style="max-width: 100px;">
                                        <form @submit.prevent="saveItem({
                                            id: item.positions.multi_modal.id,
                                            year: year,
                                            month: item.month.month_index,
                                            position: 'multi_modal',
                                            value: item.positions.multi_modal.value
                                        })">
                                            <div class="input-group">

                                                <input v-model="item.positions.multi_modal.value" type="text"
                                                    class="form-control"
                                                    :aria-describedby="`year-${year}-${item.month.month_index}`">
                                                <button class="btn" :class="{
                                                    'btn-success': item.positions.multi_modal.id,
                                                    'btn-outline-success': !item.positions.multi_modal.id,
                                                }" type="submit"
                                                    :id="`year-${year}-${item.month.month_index}`">Save</button>
                                            </div>
                                        </form>
                                    </td>
                                    <td style="max-width: 100px;">
                                        <div class="input-group">
                                            <input v-model="item.positions.rail_forwarder.value" type="text"
                                                class="form-control"
                                                :aria-describedby="`year-${year}-${item.month.month_index}`">
                                            <button class="btn" :class="{
                                                'btn-success': item.positions.rail_forwarder.id,
                                                'btn-outline-success': !item.positions.rail_forwarder.id,
                                            }" type="button"
                                                :id="`year-${year}-${item.month.month_index}`">Save</button>
                                        </div>
                                    </td> -->
                                </tr>
                                <tr>
                                    <th style="max-width: 100px;">TOTAL: $ {{ formatPrice(getYearTotalTargetValue(year))
                                        }}</th>
                                    <th v-for="position in positions" :key="`position-${position}-total`">
                                        $ {{ formatPrice(getYearTotalTargetValue(year, position)) }}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </b-card-body>
    </b-card>
</template>